import React from "react"
import { Row, Col } from "react-bootstrap"

const MoveFeelLive = props => {
  return (
    <>
      <Col sm={12}           
      style={{
            paddingLeft: '0px'
          }}>
        <Row>
          <Col className="horiz-rule"
            sm={6}
          >
            <div
              style={{
                width: "100%",
              }}
            >
              <hr
                style={{
                  borderTop: "1px solid black",
                }}
              />
            </div>
          </Col>

          <Col sm={6}>
            <div>
              <h2 className="about-title"
                style={{
                  color: "#36762F",
                  fontWeight: "bold",
                  fontSize: "87px",
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
              >
                {props.title}
              </h2>
            </div>
          </Col>
        </Row>
      </Col>
    </>
  )
}

export default MoveFeelLive
