import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"

import Icon from '../images/sync-bionics-icon.svg'
import RippleVentures from '../images/ripple-ventures-logo.svg'

import MoveFeelLive from '../components/MoveFeelLive'

import HoldingHandsSection from '../components/HoldingHandsSection'


const About = ({ data }) => (
  <Layout>
    <SEO title="About Us" />
    <Container fluid>
      <Row style={{
        marginTop: '4rem',
        marginBottom: '4rem'
      }}>
        <Col className="zero-padding-col" sm={12} md={6}>
          <Row>
            <MoveFeelLive title="MOVE" />
            <MoveFeelLive title="FEEL" />
            <MoveFeelLive title="LIVE" />
         </Row>
        </Col>
        <Col className="zero-padding-col featured-content-container" sm={12} md={6}
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column'
          }}
        >
          <Row>
            <Col style={{
              marginRight: '5%'
            }} >
            <div>
        <p style={{
          textAlign: 'justify'
        }}>
        Sync Bionics creates technology to improve the use of prosthetic devices. We design systems with a combination of the latest physiology research, innovative electronics, and advanced materials. Our team includes engineers, scientists, surgeons, and prosthetists dedicated to creating the first available implantable devices for prosthesis control.
        </p>
        
      </div>
      <Row>
            <Col className="ripple-ventures-col"
            style={{
              display: 'flex',
              flexFlow: 'column',
              justifyContent: 'space-between',
            }}>
            <div style={{
              alignSelf: 'center'
            }}>
              <img src={ RippleVentures } className="ripple-ventures-icon"
                style={{
                  width: '175px',
                  right: '0'
                }}
              />
            </div>
            <div style={{
              alignSelf: 'center'
            }}><Link to="/history" className="btn btn-success btn-sync">HISTORY</Link></div>
            </Col>
            <Col  style={{
              display: 'flex',
              flexFlow: 'column',
              justifyContent: 'space-between'
            }}>
            <div>
              <p style={{
                textAlign: 'justify'
              }}>Ripple creates neural interface and medical devices and medical devices to advanced research and improve the lives of underserved patient populations through thoughtful application of technology and design.</p>
            </div>
            <div style={{
              alignSelf: 'center'
            }} className="btn btn-success btn-sync">COMPANY INFO</div>
            </Col>
          </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export const query = graphql`
  query {
    homeBackground: file(relativePath:{eq:"header-image.jpg"}) {
      childImageSharp {
        fluid(quality:90, maxWidth:1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wave: file(relativePath:{eq:"022019-MIRA-6-whitebg.jpg"}) {
      childImageSharp {
        fluid(quality:90, maxWidth:1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default About